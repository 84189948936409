@import "vars.less";

form {


    input,
    select,
    textarea {
        width: 100%;
        color: @grey-blue;
    }

    input[type=checkbox],
    input[type=radio] {
        width: auto;
    }

    label.field-label {
        display: inline-block;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        color: @grey-blue;
    }

    .error-msg {
        margin-top: 8px;
    }

    /** New Class to use for form rows **/
    .form-row {
        margin: 24px 0;

        &.oneline {
            display: flex;

            >* {
                flex: 1 0 50%;
                box-sizing: border-box;

                &:first-child {
                    padding-right: 12px;
                }

                &:last-child {
                    padding-left: 12px;
                }
            }

        }

    }
}

.grid-form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;

    .full-row {
        grid-column: 1;
    }
}


input,
select,
textarea {
    padding: 7px 20px;
    box-sizing: border-box;
    border: 1px solid @borderColour;
    background: #fff;
    outline: none;
    box-shadow: none;
    line-height: 1.5;
    font-size: 16px;
    color: @grey-blue;
    font-family: @primaryFont;
    border-radius: @button-radius;

    transition: border-color 0.2s ease-out, background-color 0.2s ease-out;

    &:not(:disabled):not(.disabled):not(:focus):hover {
        border-color: @ui-hover-border-colour;
    }

    &:focus {
        border-color: @ui-focus-ring-colour;
    }

    &.ng-dirty.ng-invalid {
        border-color: @red  !important;
    }

}

organization-editor input {
    padding: 8.5px 20px;
    font-size: 12px;
}

input[type='checkbox'] {
    padding: 0px;
}

input[type='submit'] {
    width: auto;
}

label.required {
    &::after {
        content: '*';
        position: relative;
        left: 3px;
        top: -2px;
    }
}

.select-field-wrapper {
    position: relative;

    select {
        appearance: none;

        .ltr-styles( {
                padding-right: 40px;
            }

            , {
                padding-left: 40px;
            }

        );
    }

    &:after {
        content: "\23";
        font-family: "builder-icons";
        position: absolute;
        top: 0;
        z-index: 1;
        pointer-events: none;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 100%;
        font-size: 12px;

        .ltr-styles( {
                right: 0;
            }

            , {
                left: 0;
            }

        );
    }
}


.input-with-icon {
    position: relative;

    ab-icon {
        position: absolute;
        top: 0;
        height: 100%;
        width: 40px;
        cursor: pointer;
        display: flex;
        justify-content: center;
    }

    .ltr-styles( {
            ab-icon {
                left:0;
            }

            input {
                padding-left: 40px;
            }
        }

        , {
            ab-icon {
                right:0;
            }

            input {
                padding-right: 40px;
            }
        }

    );
}



 