@import '../mixins-typography.less';

.uk-pilot {
  // Allow components to inherit typography style for UK Pilot
  .create-alpha-simple,
  my-alphas,
  dashboard-section,
  product-list {
    .baseTypography();
    .welcome-text {
      line-height: 1;
    }
  }

  @import 'create-alpha';
  @import 'page-headers';

  .page-headline {
    font-size: 16px;
    font-weight: 400;
    color: #262635;
    margin: 0;
    line-height: 1.666;

    @media (min-width: @menuBreakPointWidth) {
      font-size: 18px;
    }
  }

  .preview-details {
    display: flex;
    gap: 24px;
    padding: 45px 0;

    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 30px 0;
    }
  }
}

.search-update {
  @media (min-width: 769px) {
    builder-menu {
      nav {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      .menu-item {
        margin-bottom: 0px;
      }
      nav > :nth-last-child(2) {
        margin-bottom: 24px;
      }
    }
  }
}
