@import "../vars";
@import "../mixins-typography.less";

#contentHeader {
  #pageTitle {
    font-size: 30px;
    font-weight: 800;
    margin: 0;
    margin-left: 0 !important;
  }

  @media (max-width: @menuBreakPointWidth) {
    #pageTitle {
      font-size: 18px;
    }
  }
}

.top-content {
  h6 {
    .h3();
    color: @lightish-grey;
    margin: 0;
  }

  @media (max-width: @menuBreakPointWidth) {
    padding-top: 30px;
  }
}
