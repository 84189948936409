@import "../vars";
@import "../mixins-typography.less";

.register-alpha {
    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 16px;
    }
}