@import 'vars.less';
@import 'mixins.less';
@import 'animations.less';
@import 'messages.less';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul,
ol {
  list-style-position: inside;
}

ul {
  &.icons-list {
    list-style: none;
    color: @red;

    a {
      color: inherit;
    }

    li {
      margin-bottom: 12px;
      transition: color 0.2s ease-out;

      &:hover {
        color: @red-hover;
      }
    }
  }
}

a img {
  border: none;
}

*.hidden {
  display: none;
}

html {
  overflow-x: hidden;
  left: 0;
  position: relative;
  -webkit-font-smoothing: antialiased;

  &.loading {
    pointer-events: none;
    overflow-y: hidden;
  }

  &.activated-panel {
    overflow: hidden;
    height: 100vh;
    overflow-x: unset;

    .intercom-lightweight-app {
      display: none;
    }

    &.show-page-panel {
      .page-content {
        z-index: 1;
      }
    }
  }

  &.hide-overflow {
    #contentContainer {
      overflow-y: hidden;
    }
    edit-alpha {
      overflow-y: hidden;
    }
  }
}

body {
  -webkit-text-size-adjust: 100%;
  font-family: @primaryFont;
  font-size: 14px;
  line-height: 1.5;
  color: @bodyFontColor;
  background-color: @bodyBackgroundColor;
}

#preloader {
  height: 100vh;
  display: flex;
  justify-content: center;
  img {
    display: block;
    width: 96px;
    animation: loadFader 1.3s ease-in-out 0s infinite reverse both running;
  }
}

.intercom-launcher,
.intercom-app > div:not(.intercom-messenger-frame) {
  bottom: 4.5rem !important;
  background: @red;
  border-radius: 50%;
}

.ltr-styles( {
        .intercom-launcher,
        .intercom-app > div:not(.intercom-messenger-frame) {
            right: 0;
            left: unset;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }, {

        .intercom-lightweight-app-messenger,
        .intercom-messenger-frame,
        .intercom-launcher-frame,
        .intercom-launcher {
            left: 1.25rem;
            right: auto;
        }

        .intercom-launcher,
        .intercom-app > div:not(.intercom-messenger-frame) {
            left: 0;
            right: unset;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

    }

);

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  transition: color 0.2s ease-out;

  &.active {
    color: @red;

    &:hover {
      color: @red-hover;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: @headerFont;
  color: @headerFontColor;
}

caption {
  text-align: left;
}

*[ng-reflect-router-link]:focus,
div[tabindex]:focus {
  outline: none;
}

*[ng-reflect-router-link] {
  cursor: pointer;
}

*[hidden] {
  display: none !important;
}

small {
  font-size: 0.9em;
}

.hide {
  display: none !important;
}

.center {
  text-align: center;

  p {
    margin-left: auto;
    margin-right: auto;
  }
}

.bold {
  font-weight: bold;
}

.black {
  color: #000;
}

section {
  margin-top: 60px;
  margin-bottom: 45px;

  @media (max-width: @menuBreakPointWidth) {
    margin-top: 25px;
    margin-bottom: 15px;
  }
}

.page-headline {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

#bodyWrapper {
  position: relative;
  background-color: @containerBackgroundColor;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  overflow: hidden;
  box-sizing: border-box;

  &::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: -1;
    opacity: 0;
    transition: background-color 0.2s ease-in-out;
  }

  &.overlay {
    &::after {
      opacity: 1;
      z-index: 2;
      pointer-events: auto;
      background-color: rgba(0, 0, 0, 0.5);
    }
    #contentContainer {
      overflow-y: hidden;
    }
    .page-footer {
      z-index: 3;
    }
  }
}

.page-header {
  z-index: 1;
}

.page-content {
  display: flex;
  overflow: hidden;
  z-index: 0;
  flex-grow: 1;
}

.page-footer {
  z-index: 0;
}

#pageBody {
  position: relative;
}

#contentContainer {
  overflow-y: auto;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

#pageTitle {
  margin: 0;
  display: inline-block;
  padding: 1.5rem 0;

  @media (max-width: @menuBreakPointWidth) {
    padding: 1rem 0;
  }
}

#contentHeader {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

#TitleHeader {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  #pageTitle {
    font-size: 30px;
  }
  .divider {
    height: 2px;
    background: @grey-10;
  }
}

.content-wrapper {
  .content-wrapper();
}

.content-sections-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
  margin-bottom: 4.5rem;
}

.card-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &.archive-card-list {
    gap: 1rem;
  }
}

.top-content {
  padding-top: 40px;

  > *:first-child {
    margin-top: 0;
  }

  @media (max-height: 600px) {
    padding-top: @short-top-gutter;
  }
}

.section-title {
  margin-bottom: 1.25rem;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid @grey-border;
  margin: 2.5em 0;
  padding: 0;
}

ab-icon {
  &[name='chevron-down'],
  &[name='chevron-up'] {
    transform: rotate(0deg);
    transform-origin: 50%;
    transition: transform 0.2s ease-out;

    &.toggle {
      transform: rotate(180deg);
    }
  }
}

.button-container {
  box-sizing: border-box;
  border-radius: @button-radius;
  padding: @button-v-padding @button-h-padding;

  &.grey {
    background-color: @ui-grey-bg;
    color: #fff;

    .link {
      color: rgba(255, 255, 255, 0.7);
      transition: color 0.2s ease-out;

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  a {
    color: inherit;
  }
}

.error-msg {
  color: @red;
  font-size: 13px;
}

.arrow {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  transform: rotate(45deg);
  top: -4px;
  right: 16px;
  box-shadow: -2px -3px 5px -3px rgba(0, 0, 0, 0.5);
}

.duplicate-alpha-btn {
  position: relative;
}

/** Google maps ui overlay elements need to sit above our overlays **/
.pac-container {
  z-index: 1000001;
}

.header-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: @menuBreakPointWidth) {
    flex-direction: column;
    align-items: start;
  }
}

@media (max-width: @menuBreakPointWidth) {
  .divider-mobile {
    width: 100%;
    height: 2px;
    background: @grey-10;
    margin-bottom: 1.5rem;
  }

  .divider {
    display: none;
  }

  #navRegisterAlphaButton {
    width: 100%;
  }
}

@import 'ui-common.less';
@import 'forms.less';
@import 'cards.less';
@import 'wysiwyg.less';
@import 'layouts.less';
@import 'responsive.less';
@import 'uk-pilot/main.less';
@import 'cora/main.less';
