$color_1: #fff;
$font-family_1: revicons;
$border-color_1: grey;

@font-face {
	font-family: "revicons";
	fallback: fallback;
	src: url("/node_modules/react-multi-carousel/lib/revicons.woff") format('woff'),url("/node_modules/react-multi-carousel/lib/revicons.ttf") format('ttf'),url("/node_modules/react-multi-carousel/lib/revicons.eot") format('ttf');
}
.react-multi-carousel-list {
	display: flex;
	align-items: center;
	overflow: hidden;
	position: relative;
}
.react-multi-carousel-track {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	position: relative;
	transform-style: preserve-3d;
	backface-visibility: hidden;
	will-change: transform,transition;
}
.react-multiple-carousel__arrow {
	position: absolute;
	outline: 0;
	transition: all .5s;
	border-radius: 35px;
	z-index: 1000;
	border: 0;
	background: rgba(0,0,0,0.5);
	min-width: 43px;
	min-height: 43px;
	opacity: 1;
	cursor: pointer;
	&:hover {
		background: rgba(0,0,0,0.8);
	}
	&::before {
		font-size: 20px;
		color: $color_1;
		display: block;
		font-family: $font-family_1;
		text-align: center;
		z-index: 2;
		position: relative;
	}
	&:disabled {
		cursor: default;
		background: rgba(0,0,0,0.5);
	}
}
.react-multiple-carousel__arrow--left {
	left: calc(4% + 1px);
	&::before {
		content: "\e824";
	}
}
.react-multiple-carousel__arrow--right {
	right: calc(4% + 1px);
	&::before {
		content: "\e825";
	}
}
.react-multi-carousel-dot-list {
	position: absolute;
	bottom: 0;
	display: flex;
	left: 0;
	right: 0;
	justify-content: center;
	margin: auto;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
}
.react-multi-carousel-dot {
	button {
		display: inline-block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		opacity: 1;
		padding: 5px 5px 5px 5px;
		box-shadow: none;
		transition: background .5s;
		border-width: 2px;
		border-style: solid;
		border-color: $border-color_1;
		padding: 0;
		margin: 0;
		margin-right: 6px;
		outline: 0;
		cursor: pointer;
		&:hover {
			&:active {
				background: #080808;
			}
		}
	}
}
.react-multi-carousel-dot--active {
	button {
		background: #080808;
	}
}
.react-multi-carousel-item {
	transform-style: preserve-3d;
	backface-visibility: hidden;
}
[dir='rtl'].react-multi-carousel-list {
	direction: rtl;
}
.rtl.react-multiple-carousel__arrow--right {
	right: auto;
	left: calc(4% + 1px);
	&::before {
		content: "\e824";
	}
}
.rtl.react-multiple-carousel__arrow--left {
	left: auto;
	right: calc(4% + 1px);
	&::before {
		content: "\e825";
	}
}
@media all and (-ms-high-contrast:none) {
	.react-multi-carousel-item {
		flex-shrink: 0 !important;
	}
	.react-multi-carousel-track {
		overflow: visible !important;
	}
}
@media (-ms-high-contrast:active) {
	.react-multi-carousel-item {
		flex-shrink: 0 !important;
	}
	.react-multi-carousel-track {
		overflow: visible !important;
	}
}
