@import "vars";

// Typography Mixins
.h1() {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 800;

  @media (min-width: 769px) {
    font-size: 30px;
  }
}

.h2() {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 800;

  @media (min-width: 769px) {
    font-size: 24px;
  }
}

.h3() {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 800;

  @media (min-width: 769px) {
    font-size: 24px;
  }
}

.h4() {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 800;

  @media (min-width: 769px) {
    font-size: 18px;
  }
}

.h5() {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;

  @media (min-width: 769px) {
    font-size: 18px;
  }
}

.h6() {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 800;

  @media (min-width: 769px) {
    font-size: 16px;
  }
}

.body1(@weight: 400) {
  font-size: 14px;
  line-height: 19px;
  font-weight: @weight;
}

.body2() {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
}

.button() {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

.caption() {
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  display: block;
  text-align: left;
}

.input() {
  .body1(400);
  padding: 8px 16px;
  line-height: 22px;
  border-color: @grey-10;

  &::placeholder {
    opacity: 1;
    color: @placeholder-grey;
  }
}

// Helper Mixins
.color-transition(@duration: 300ms) {
  transition-property: color, background;
  transition-duration: @duration;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.button-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  border-radius: @button-radius;
  font-size: 14px;
  cursor: pointer;
  margin-right: 0;
  border: 1px solid @grey-light;
  color: @grey-blue;
  background: @white;
  .color-transition();

  &:hover {
    color: @white;
    background: @purple-light;
  }

  &.selected {
    background: @purple;
    color: @white;
  }
}

.mya-h1,
.mya-h2,
.mya-h3,
.mya-h4,
.mya-h5,
.mya-h6,
.mya-p,
.mya-label,
.mya-caption {
  margin: 0;
}
.mya-h1 {
  .h1();
}
.mya-h2 {
  .h2();
}
.mya-h3 {
  .h3();
}
.mya-h4 {
  .h4();
}
.mya-h5 {
  .h5();
}
.mya-h6 {
  .h6();
}
.mya-p {
  .body1();
}
.mya-label {
  .body1(500);
  color: @text-grey;

  &.mat-radio-label {
    font-weight: 400;
  }
}
.mya-caption {
  .caption();
}

.mya-button-black {
  &.mat-mdc-unelevated-button:not(:disabled) {
    background-color: @primary-black;
    background-color: black !important;
  }
}

.baseTypography() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  label,
  caption {
    margin: 0;
  }

  h1,
  h2,
  h3 {
    font-family: @headerFont;
    color: @text-grey;
  }

  h4 {
    font-family: @headerFont;
    color: @grey;
  }

  h5,
  h6,
  p,
  label,
  caption {
    font-family: @primaryFont;
  }

  h1 {
    .h1();
  }
  h2 {
    .h2();
  }
  h3 {
    .h3();
  }
  h4 {
    .h4();
  }
  h5 {
    .h5();
  }
  h6 {
    .h6();
  }
  p {
    .body1();
  }
  label {
    .body1(500);
    color: @text-grey;

    &.mat-radio-label {
      font-weight: 400;
    }
  }
  caption {
    .caption();
  }
}
