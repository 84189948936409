@import "vars.less";

.text-icon-link {
    transition: color 0.2s ease-out;
    color: @red;
    cursor: pointer;

    &:hover,
    &.selected {
        color: @red-hover;
    }

    &.selected {
        cursor: default;
    }

    &.grey {
        color: @grey-light;

        &:hover {
            color: @grey;
        }

        &.selected {
            color: @grey-darkest;
        }
    }
}

button {
    &:focus {
        outline: none;
    }
}

button.mat-flat-button, a.mat-flat-button, button.mat-raised-button {
    border-radius: 6px;
    padding: 0 29px;
    min-width: 150px;
}

button.mat-flat-button {
    line-height: 32px;
}

.mat-mdc-button.mat-mdc-button-base.mya-text-button {
    font-size: 14px;
    font-weight: 400;
    color: @purple-dark;
    cursor: pointer;
    height: 27px;

    &.inlineButton {
        padding: 0;
        height: auto;

        &:hover {
            .mdc-button__ripple {
                display: none;
            }

            .mdc-button__label::after {
                content: "";
                display: block;
                position: absolute;
                border-bottom: 1px solid @purple-light;
            }
        }
    }

    .mdc-button__label {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}