@import 'vars.less';
@import 'mixins-typography';

.content-wrapper() {
  display: block;
  width: 100%;
  position: relative;
  max-width: 1313px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  &.has-back-link {
    display: flex;
    gap: 1rem;
  }

  .back-link {
    cursor: pointer;
    font-size: 24px;
  }

  @media (min-width: 960px) {
    padding-left: 5rem;
    padding-right: 5rem;

    .back-link {
      position: absolute;
      left: 2.5rem;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  @media (min-width: 1440px) {
    padding-left: 6rem;
    padding-right: 6rem;

    .back-link {
      left: 3rem;
    }
  }
}

.ltr-styles(@ltr, @rtl) {
  html[dir='rtl'] & {
    @rtl();
  }

  html:not([dir='rtl']) & {
    @ltr();
  }
}

.host-ltr-styles(@ltr, @rtl) {
  :host-context(html[dir='rtl']) & {
    @rtl();
  }

  :host-context(html:not([dir='rtl'])) & {
    @ltr();
  }
}

.circle(@size) {
  border-radius: 50%;
  width: @size;
  height: @size;
  line-height: @size;
  text-align: center;
}

.grid() {
  display: flex;
  flex-wrap: wrap;

  /* creates an equal outer gap (optional) */
  padding: 20px 0 0 20px;

  /* pulls grid cells hard against edges */
  margin: -20px;

  > * {
    .grid-cell();
  }
}

.grid-cell() {
  box-sizing: border-box;
  /* creates gaps */
  border: 0 solid transparent;
  border-width: 0 24px 24px 0;

  /* prevents background bleed */
  background-clip: padding-box;
}

.keyframes(@name;

    @arguments) {
  @-moz-keyframes @name {
    @arguments();
  }

  @-webkit-keyframes @name {
    @arguments();
  }

  @keyframes @name {
    @arguments();
  }
}

.builder-icon {
  font-size: 16px;
}

.card-component {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
  padding: 20px 24px;
  width: 640px;
  max-width: 100%;
  background: @white;
  border-radius: 6px;
  box-shadow: 0px 4px 16px rgba(44, 79, 98, 0.2);
  position: relative;
  box-sizing: border-box;
}

.card-promo() {
  border-radius: 0.75rem;
  background: @white;
  box-shadow: @card-shadow;
  padding: 1.75rem;

  @media (max-width: 960px) {
    padding: 1.5rem;
  }

  & > *:not(img):not(:last-child) {
    margin-bottom: 1rem;
  }

  &:after {
    content: '';
    clear: both;
    display: table;
  }

  .card-description {
    font-size: 1rem;
    line-height: 1.5;
  }

  .cta-button {
    white-space: nowrap;
    width: min-content;

    @media (max-width: 560px) {
      width: 100%;
    }
  }
}
