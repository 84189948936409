.column-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-gap: 16px;
    display: -ms-grid;
    -ms-grid-columns: 210px 210px 210px;

    .card-body {
        padding: 20px;
    }

    &-2-column {
        grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
	}
	&-3-column {
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
	}
	
	
}

/**
TESTING
display: flex;
    flex-flow: row wrap;
    margin-left: -1.3346%;
    width: 103%;


    >* {
        margin: 1.3346%;
        flex: 0 0 208px;
    }
**/

.button-grid {
    display: inline-grid;
    grid-gap: 16px;
}

.flex-centered-justify-edges {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


@media screen and (min-width:768px){
	.column-box {
		grid-gap: 24px;
	}
}

@media (min-width:1440px){
	.column-box {
		grid-template-columns: repeat(3, minmax(300px, 1fr));
		grid-gap: 24px 32px;

		&-2-column {
			grid-template-columns: repeat(2, minmax(400px, 1fr));
		}
		&-3-column {
			grid-template-columns: repeat(3, minmax(300px, 1fr));
		}
	}
	
}