@use '@angular/material' as mat;

@include mat.core();
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$builder-typography: mat.define-typography-config(
	$font-family : '"Avenir", sans-serif',
	$body-1: mat.define-typography-level(
		$font-size:14px,
		$line-height:1.5,
		$letter-spacing:normal
	)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$light-grey: #c7c7c7;
$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$builder-purple: (50: #ede7f6,
    100: #d1c4e9,
    200: #b39ddb,
    300: #8e8edd,
    400: #7e57c2,
    500: #4d4da6,
    600: #5e35b1,
    700: #512da8,
    800: #4527a0,
    900: #311b92,
    A100: #b388ff,
    A200: #7c4dff,
    A400: #651fff,
    A700: #6200ea,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ));

$builder-red: (50: #ffebee,
    100: #ffcdd2,
    200: #ef9a9a,
    300: #e57373,
    400: #ef5350,
    500: #e42312,
    600: #e53935,
    700: #d32f2f,
    800: #c62828,
    900: #b81c0e,
    A100: #ff8a80,
    A200: #ff5252,
    A400: #ff1744,
    A700: #d50000,
    contrast: (50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ));


$builder-app-primary: mat.define-palette($builder-red);

$builder-app-accent: mat.define-palette($builder-purple);

$builder-app-warn: mat.define-palette($builder-red);

// Create the theme object (a Sass map containing all of the palettes).
$builder-app-theme: mat.define-light-theme((
	color: (
		primary: $builder-app-primary,
		accent: $builder-app-accent,
		warn: $builder-app-warn
	),
	typography: $builder-typography
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

//@include mat-base-typography($builder-typography);

/**
* To include ALL the material styles use the angular-material-theme
*
* @include angular-material-theme($builder-app-theme);
*/

/**
* Include specific aspects of the theme
* refere to node_modules/@angular/material/_theming.scss
*
*/
@include mat.core-theme($builder-app-theme);
@include mat.button-theme($builder-app-theme);
@include mat.checkbox-theme($builder-app-theme);
@include mat.radio-theme($builder-app-theme);
@include mat.progress-spinner-theme($builder-app-theme);
@include mat.progress-bar-theme($builder-app-theme);
@include mat.menu-theme($builder-app-theme);
@include mat.dialog-theme($builder-app-theme);
@include mat.chips-theme($builder-app-theme);
@include mat.datepicker-theme($builder-app-theme);
@include mat.snack-bar-theme($builder-app-theme);
@include mat.tooltip-theme($builder-app-theme);
@include mat.select-theme($builder-app-theme);

/** Variable Overrides **/
.mat-mdc-dialog-container {
	--mdc-dialog-supporting-text-color:rgba(0,0,0,.87);
	--mdc-dialog-supporting-text-tracking: normal;
	--mdc-dialog-supporting-text-size: 14px;
    --mdc-dialog-container-shape: 1rem;
}

.mdc-icon-button.mat-mdc-icon-button {
	--mdc-icon-button-state-layer-size:40px;
	padding:0;
}

.cdk-overlay-pane.resource-modal {

    max-height: 100vh;

    @media (min-width:768px) and (min-height:568px) {
        width: auto !important;
    }

    @media (max-width:767px),
    (max-height:567px) {

        max-width: none !important;
        width: 100vw !important;
        height: 100vh !important;

    }

    iframe {
        max-width: none;
    }
}

.cdk-overlay-container {
   z-index: 1000000;
}

body.driver-active .cdk-overlay-container {
     z-index: 10000000000;
}

.cdk-overlay-pane .mat-mdc-menu-panel {
    max-width: none;
}


.mdc-button {
	--mdc-typography-button-letter-spacing:normal;
	&.mat-unthemed {
		font-weight:500;
		color:rgba(0,0,0,0.8);
	}
}

.mdc-button.mdc-button--unelevated {
	transition: background-color 0.2s ease-out, color 0.2s ease-out;
}

.mdc-button.mat-mdc-unelevated-button {
	--mdc-typography-button-letter-spacing: normal;   

	&[disabled] {
		--mdc-filled-button-disabled-container-color: #c7c7c7;
		--mdc-filled-button-disabled-label-text-color: #fff;
	}

    &:not(.mat-primary):not(.mat-accent):not(.next):not(.submit) {
        background-color: #f5f5f5;
    }

    &.mat-primary {
        font-weight: 500;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);

        &.mya-button-black {
            background-color: #1D2432;
            border-radius: 12px;
            font-size: 16px;
            height: 48px;
            padding: 0 24px;
        }

        &:not([disabled]):hover {
            background-color: mat.get-color-from-palette($builder-app-primary, 900);

            &.mya-button-black {
                background-color: #09090A;
            }
        }
    }

    &.mat-accent {
        &:hover {
            background-color: mat.get-color-from-palette($builder-app-accent, 300);
        }
    }

    &+.mat-flat-button {
        margin-left: 8px;
    }
}

mat-checkbox {

    /** Checkboxes are round **/
    &.round {

		label {
			padding-left:0;
		}

		.mdc-checkbox {
			position: relative;
			top:-1px;
			margin-left:-11px;
		}

        .mdc-checkbox__background {
            border-radius: 50%;
        }
    }
	input:not([disabled]):focus ~ .mdc-checkbox__ripple {
		opacity:0 !important;
	}
}

mat-radio-group {
    display: inline-flex;
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
}

mat-radio-button {
    .mdc-form-field {
        .mdc-radio {
            padding: 0;
        }

        label {
            padding-left: 8px;
            font-weight: 400;
        } 
    }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #e42312;
}

/** ab "form label" selector adds unwanted bottom margin on checkbox layouts **/
form .mat-checkbox-layout {

    margin-bottom: 0;
    white-space: unset;
    align-items: center;

    .mat-checkbox-label {
        line-height: 16px;
    }
}

.mat-datepicker-toggle {
    &:focus {
        outline: none;
    }

    >button[disabled] {
        background: none;

        &.mat-button-disabled {
            color: unset;
        }
    }

}

.mat-tab-nav-bar {
    &.compact {
        .mat-tab-header-pagination {
            min-width: 15px;

            &-before {
                justify-content: flex-start;
            }

            &-after {
                justify-content: flex-end;
            }

            &-disabled {
                opacity: 0.3;
            }
        }
    }
}