@use "../../node_modules/ngx-popperjs/scss/theme" as popperBaseTheme;

$background-color: #1D2432;
$text-color: #fff;
$max-width: 340px;
$z-index: 100;

body {
    @include popperBaseTheme.ngx-popperjs-theme($background-color, $text-color, $max-width, $z-index);
    .ngxp__container {
        font-size: 12px;
        font-weight: 400;
        padding: 14px;
        border-radius: 6px;
        z-index: $z-index;
        min-width: 240px;

        .ngxp__inner {
            caption {
                color: #fff;
            }

            a {
                color: #fff;
                font-weight: 500;
                text-decoration: underline;

                &:hover {
                    color: #eee;
                }
            }
        }
    }
}