@red: #e42312;
@red-hover: #b81c0e;
@red-lightest: #fce5e3;
@red-dark: #b81e10;
@light-red: #fdc2bd;
@ui-primary-red: #fce9e7;
@ui-primary-green:#DFE1DC;
@ui-secondary-red: #fad3d0;
@light-grey: #d7d7d7;
@lightish-grey: #717171;
@placeholder-grey: #888;
@dark-grey: #38393b;
@darker-grey: #242526;
@headline-grey: #505050;
@ui-primary-grey: #e9ebef;
@ui-secondary-grey: #d4d7df;
@outline-green: #a6e189;
@text-grey: #1d2432;
@text-med-grey: #5d6368;
@light-grey-on-white: #c9c9c9;
@ui-grey: #e0e0e0;
@bg-grey: #fafafa;
@grey-border: #dfdfdf;
@borderColour: #e6e7e8;

@headerFont: 'Avant Garde', sans-serif;
@primaryFont: 'Avenir', Helvetica, Arial, sans-serif;

/** New Variable ADP 2.0 **/

@white: #ffffff;
@purple: #4d4da6;
@purple-lightest: #e6ecff;
@purple-light: #8e8edd;
@purple-medium: #4a4a6b;
@purple-dark: #3e3ede;
@purple-100: #dbdbed;
@navy-60: #7d879e;
@navy-light: #3d5285;
@navy-dark: #27375e;
@purple-2: #b9addb;

@menuBreakPointWidth: 768px;
@mediumBreakPointWidth: 960px;
@smallBreakPointWidth: 480px;
@contentMaxWidth: 680px;

@bodyFontColor: #1d2432;
@bodyBackgroundColor: #f2f2f2;

@containerBackgroundColor: #f9fbfc;

@grey-blue: #222b3c;

@grey: #717171;
@grey-lightest: #ededed;
@grey-light: #c7c7c7;

@grey-light-separator: #c4c4c4;
@grey-medium: #878787;
@grey-mediumish-dark: #939393;
@grey-medium-dark: #6c6c6c;
@grey-darkest: #1d2432;

@headerFontColor: @grey-blue;

@hr-color: #dedede;
@table-border-color: #dedede;

@menu-bg: @grey-darkest;
@user-bg: #fdfcfb;
@menu-item-selected-bg: #2d3340;
@user-hover-item-selected-bg: #fce9e7;
@user-item-selected-bg: #fdf4f3;
@user-item-guest-app-bg:#F7F6F3;
@mobile-menu-height: 60px;
@mobile-header-height: 48px;

//@card-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.3);
@card-shadow: 0px 4px 16px rgba(44, 79, 98, 0.2);
//@card-hover-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
@card-hover-shadow: 0px 6px 16px rgba(44, 79, 98, 0.4);

@window-top-gutter: 35px;
@window-side-gutter: 25px;
@desktop-lg-window-side-gutter: 25px;
@desktop-window-side-gutter: 25px;
@mobile-window-side-gutter: 20px;

@short-top-gutter: 20px;

/** Gutter next to sidenav **/
@content-left-gutter: 68px;

/** Ui Elements **/
@ui-grey-bg: #343a47;
@ui-light-grey-bg: #ececec;
@ui-disabled-grey: #c7c7c7;
@ui-focus-ring-colour: #a6a6d2;
@ui-hover-border-colour: #b8b8b8;

@ui-calendar-border: #e4e4e4;

@button-radius: 6px;
@button-v-padding: 10px;
@button-h-padding: 20px;
@button-wide-padding: 55px;

@dark-blue: #1e2532;

.grey() {
  100: #09090a;
  90: #212122;
  80: #3a3a3a;
  70: #525252;
  60: #6b6a6a;
  50: #838282;
  40: #9b9b9b;
  30: #b4b3b3;
  20: #cccbcb;
  10: #e5e4e3;
  5: #f9f9f9;
}

@primary-black: .grey[100];
@grey-100: .grey[100];
@grey-90: .grey[90];
@grey-80: .grey[80];
@grey-70: .grey[70];
@grey-60: .grey[60];
@grey-50: .grey[50];
@grey-40: .grey[40];
@grey-30: .grey[30];
@grey-20: .grey[20];
@grey-10: .grey[10];
@grey-5: .grey[5];

each(.grey(), {
   .color-grey-@{key} {
       color: @value;
   }
   .bgcolor-grey-@{key} {
       color: @value;
   }
});

@purple-dark-90: #5c5c7a;
@purple-dark-150: #1d2432;

.font-weight() {
  medium: 400;
  bold: 500;
  heavy: 800;
}

each(.font-weight(), {
   .font-weight-@{key} {
       font-weight: @value;
   }
});
