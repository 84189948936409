@import "mixins.less";
@import "vars.less";

.ab-message {
    font-size: 0.88em;
    border: 1px solid #d7d7d7;
    border-left-width: 6px;
    border-left-style: solid;
    padding: 14px 14px 14px 20px;
    background-color: #fff;
	div&{
		margin-top: 16px;
		margin-bottom: 16px;
	}
    span& {
        display: inline-block;
    }

    &.error {
        border-left-color: @red;
    }

    &.success {
        border-left-color: green;
	}
	p:first-child{
		margin-top:0;
	}
	p:last-child{
		margin-bottom:0;
	}
	a{
		color:@red;
	}
}