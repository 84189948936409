@import "../mixins-typography.less";


.cora {
    // Allow components to inherit typography style for Cora
    .register-alpha {
        .baseTypography();
    }

    @import "create-alpha";
    @import "page-headers";
}