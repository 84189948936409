// This file contains style overrides for the simplified Create Alpha flow for the UK Pilot

@import "../vars.less";
@import "../mixins-typography.less";

.create-alpha-simple,
my-alphas {

    .form-row-simple {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .form-simple {
        display: flex;
        flex-direction: column;
        gap: 30px;
        max-width: 392px;

        @media (max-width:768px) {
            gap: 20px;
        }

        label.field-label {
            margin: 0;
            font-weight: 500;

            &.required::after {
                content: none;
            }
        }
        
        input,
        select,
        textarea {
            .input();
        }

        // Start Date
        .mat-mdc-icon-button .mat-mdc-button-touch-target {
            height: 40px;
            width: 48px;
        }

        .mat-datepicker-toggle {
            left: 5px;
        }

        // Delivery Radio Buttons
        mat-radio-group {
            flex-direction: row;
            gap: 16px;

            label {
                font-weight: 400;
            }
        }

        .mat-radio-container .mat-radio-outer-circle {
            color: @grey-light;
        }
    }

    .card-title {
        .h2();
        margin-bottom: 0px;
    }
    .card-body {
        padding: 24px 32px;
        gap: 12px;
    }
    .card-meta-icon-list {
        gap: 24px;

        > ab-icon {
            margin: 0 !important;
            color: @placeholder-grey;
        }
    } 
    card product-meta-bar {
        margin: 0;
    }
    .additional-options h6 {
        font-family: @headerFont;
        .h4();
    }

    .time-picker-component {
        gap: 12px;

        .picker-inputs {
            margin-right: 0 !important;
            border-color: @grey-light;
        }
    }

    .ampm-selector {
        gap: 8px;

        .button-trigger {
            .button-toggle();
        }
        html:not([dir=rtl]) .button-trigger:first-child {
            margin-right: 0;
        }
    }
}