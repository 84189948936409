// This file contains style overrides for the simplified Create Alpha flow for the UK Pilot

@import "../vars.less";
@import "../mixins-typography.less";

.register-alpha,
#alpha-details-wrapper,
my-alphas {
  .form-row-simple {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .tooltip-container {
      gap: 43px;
    }
  }

  .form-simple {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 480px;
    margin-bottom: 36px;

    @media (max-width: 768px) {
      gap: 20px;
      margin-bottom: 10px;
    }

    label.field-label {
      margin: 0;
      font-weight: 500;

      &.required::after {
        content: none;
      }
    }

    input,
    select,
    textarea {
      .input();
    }

    organization-editor-simple {
      input {
        &.ng-touched {
          border-color: red;
        }
      }
    }

    // Start Date
    .mat-mdc-icon-button .mat-mdc-button-touch-target {
      height: 40px;
      width: 48px;
    }

    // Delivery Radio Buttons
    mat-radio-group {
      flex-direction: row;
      gap: 16px;

      label {
        font-weight: 400;
      }
    }

    .mat-radio-container .mat-radio-outer-circle {
      color: @grey-light;
    }
  }

  .card-title {
    .h2();
    margin-bottom: 0px;
  }
  .card-body {
    padding: 1.25rem;
  }
  .card-meta-icon-list {
    gap: 24px;

    > ab-icon {
      margin: 0 !important;
      color: @placeholder-grey;
    }
  }
  @media (max-width: 768px) {
    .card-meta {
      .alpha-date {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
  card product-meta-bar {
    margin: 0;
  }

  notification {
    &:not(.notification-inline) {
      ab-icon {
        color: black !important;
      }
    }
  }
}
