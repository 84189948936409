@import "vars.less";
@import "mixins.less";

card {
    display: block;


    &.hoverable {
        .card-wrapper {
            transition: box-shadow 0.2s ease-out;
        }

        .link-title {
            transition: color 0.2s ease-out;
            cursor: pointer;
        }

        &:hover {

            .card-wrapper {
                box-shadow: @card-hover-shadow;
            }

            .link-title {
                color: @red;

                &.active {
                    color: @red-hover;
                }
            }
        }
    }

    &.image-on-top {

        .card-wrapper {
            flex-flow: column wrap;
        }

        .card-image {
            min-height: 82px;
            width: 100%;
        }
    }

    &.image-on-right {
        .card-image {
            order: 3;
            flex: 0 0 28%;
        }
    }

    &.image-on-left {

        .card-wrapper {
            flex-flow: row wrap;
        }


        .card-image {
            position: absolute;
            height: 100%;
            width: 80px;
        }

        .card-body,
        .card-footer {
            .ltr-styles( {
                    padding-left: 100px;
                }

                , {
                    padding-right: 100px;
                }

            );

        }
    }

    &.image-none {
        .card-image {
            display: none;
        }
    }

    .subtitle {
        font-size: 14px;
    }


}

.card {

    &-wrapper {
        display: flex;
        flex-flow: row nowrap;
        //box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.3);
        box-shadow: @card-shadow;
        font-size: 12px;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        background-color: #fff;
        height: 100%;
    }

    &-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-color: @purple-medium;
        color: #fff;
        font-size: 17px; // for icons in here

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 100%; //height:auto;
        }

    }

    &-footer {
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px 20px 20px;
    }

    &-body {

        padding: 4% 5%; // experimental, hasn't been tested with other cards. trying it out on the publish to alpha card on alpha details
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        >*:first-child {
            margin-top: 0;
        }
    }

    &-description {
        line-height: 1.9;
        font-size: 0.888em
    }

    &-title {

        margin-bottom: 16px;
        color: @bodyFontColor;

        &:first-child:last-child {
            margin-bottom: 0px;
        }
    }

    &-chips {
        position: absolute;
        z-index: 9;
        transform: translateY(-50%);

        .ltr-styles( {
                margin-left: 20px;
            }

            , {
                margin-right: 20px;
            }

        );


        .mat-chip {
            font-size: 10px;
            min-height: 20px;

            &:hover::after,
            &:focus::after {
                opacity: 0;
            }
        }
    }

    &-meta-icon-list {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        gap: 0.5rem 2rem;

        ab-icon {
            &::before {
                font-size: 15px;
            }

            &[name=chevron-down] {
                cursor: pointer;

                &::before {
                    font-size: 10px;
                }

                .ltr-styles( {
                        margin-left: 6px;
                    }

                    , {
                        margin-right: 6px;
                    }

                );

            }

            ::ng-deep .builder-icon {
                font-size: 1.2em;
            }
        }
    }

}

@media(max-width: 768px) {

    card {
        &.image-on-left {
            .card-title {
                margin-bottom: 0;
            }
        }

        &.image-on-top {
            .card-image {
                min-height: 120px;
            }
        }
    }

    .card {
        &-body {
            padding: 20px;
        }
    }
}

@media(min-width:1024px) {
    .card-body {
        padding: 20px 36px;
    }
}