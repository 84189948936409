@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Avant Garde-Bold';
	src: url('/fonts/ITCAvantGardeStd-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Avant Garde-Light';
	src: url('/fonts/ITCAvantGardeStd-book.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Avant Garde';
	src: url('/fonts/ITCAvantGardeStd-demi.woff') format('woff');
	font-weight: 500;
}

@font-face {
	font-family: 'Avenir';
	src: url('/fonts/Avenir-Medium.tff') format('tff');
	font-weight: normal;
	font-style: normal;
}

@layer base {
	html {
		font-family: Avant Garde, Avenir, Helvetica, Baskerville, Arial, sans-serif;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: revert;
	}

	h1 {
		font-size: 30px;
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
	}
	  
	h2 {
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
		font-size: 28px;
	}
	  
	h3 {
		margin-top: 16px;
		margin-bottom: 16px;
		font-size: 26px;
	}
	  
	h4 {
		margin-top: 16px;
		margin-bottom: 16px;
		font-size: 24px;
	}
	  
	h5 {
		margin-top: 16px;
		margin-bottom: 16px;
		font-size: 18px;
		font-weight: 700;
	}
	  
	h6 {
		margin-top: 16px;
		margin-bottom: 16px;
		font-size: 16px;
	}

	p {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	input, select, textarea {
		padding: 7px 20px;
		font-size: 16px;
	}
}

@layer components {
	[role="tabpanel"] h1 {
		font-size: x-large;
		font-family: ItcAvantGarde;
		font-weight: 600;
	}
	[role="tabpanel"] h2,
	[role="tabpanel"] p {
		font-family: Avenir;
	}
	[role="tabpanel"] h2 {
		font-size: 1.25em;
		font-weight: 600;
	}
	[role="tabpanel"] p {
		font-weight: 400;
	}
	button,
	div[role="button"] {
		cursor: pointer;

		&:disabled {
			cursor: default;
		}
	}

	button {
		appearance: auto;
		font-style: inherit;
		font-variant-ligatures: inherit;
		font-variant-caps: inherit;
		font-variant-numeric: inherit;
		font-variant-east-asian: inherit;
		font-variant-alternates: inherit;
		font-variant-position: inherit;
		font-weight: inherit;
		font-stretch: inherit;
		font-size: inherit;
		font-family: inherit;
		font-optical-sizing: inherit;
		font-size-adjust: inherit;
		font-kerning: inherit;
		font-feature-settings: inherit;
		font-variation-settings: inherit;
		text-rendering: auto;
		letter-spacing: normal;
		word-spacing: normal;
		line-height: normal;
		text-transform: none;
		text-indent: 0px;
		text-shadow: none;
		display: inline-block;
		text-align: center;
		box-sizing: border-box;
		margin: 0;
		padding-block: 0;
		padding-inline: 0;
		border-width: unset;
		border-style: outset;
		border-image: initial
	}

}