$white: #f9fbfc;
$purple: #4D4DA6;

.driver-popover.mya-driver-js {
  border-radius: 10px;
  color: $white;
  background: $purple;
  padding-top: 1.25rem;
  overflow: hidden;

 .driver-popover-title {
    font-family: "Avant Garde", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
  }

  .driver-popover-progress-text {
    color: $white;
  }

  .driver-popover-description {
    font-size: 16px;
    margin: 1.5rem 0;
  }
  
  .driver-popover-description,
  .driver-popover-progress-text {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
  }
  
  .driver-popover-navigation-btns {
    justify-content: end;
    gap: 3px;
    z-index: 1;
  }

  .driver-popover-footer {
    flex-direction: row-reverse;
  
    & button {
      font-family: "Avenir", Helvetica, Arial, sans-serif;
      background: $white; 
      min-width: 64px;
      height: 36px;
      font-size: 14px;
      text-align: center;
      color: $purple;
      opacity: 0.9;

      &:hover {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }

      &.driver-popover-btn-disabled {
        opacity: 0;
      }

      &.driver-popover-next-btn {
        box-shadow: none;

        &:hover {
          box-shadow: 2px 2px 8px rgba(44, 79, 98, 0.1);
        }
      }

      &.mya-driver-secondary-btn {
        background:rgba(77,77,166,0);
        color: $white;
        text-shadow: none;
        transition: background 0.2s ease-in-out;

        &:hover {
          background:rgba(57,57,146,0.8);
        }
      }
    }
  }

  .mya-driver-js-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  .mya-driver-js-btn {
    border: 0;
  }

  .mya-driver-js-exit-btn {
    border: 0;
    background: none;
    display: flex;
    gap: 4px;
    padding: 0;
    opacity: 0.9;
    transition: color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    color: $white;
    font-weight: 600;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    .builder-icon {
      width: 16px;
      height: 16px;
    }
  }

  .mya-driver-js-icon {
    position: absolute;
    font-size: 3rem;
    bottom: -1rem;
    right: 1rem;
    opacity: 0.04;
    color: $white;
    transform: scale(4.5);
    pointer-events: none;
  }
}

.driver-overlay {
  z-index: 0 !important;
}

.driver-active .driver-overlay, .driver-active * {
  pointer-events: auto !important;
}

.mobile-driver-overlay {
 z-index: 10000000000 !important;
}

.driver-popover-mobile{
  z-index: 10000000000 !important;
}

.tour-header{
  z-index: 0;
}