@import "mixins.less";

.keyframes(loadFader, {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .3;
    }

    100% {
        opacity: 1;
    }
}

);

.keyframes(fadeIn, {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

);

.keyframes(fadeInSlideUp, {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
        transform: translateY(20%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

);