@import 'mixins.less';
@import 'vars.less';

.wysiwyg {
  ul {
    ul {
      padding-left: 20px;
      margin: 5px 0 10px 0;
    }

    li {
      margin-bottom: 4px;
    }
  }

  ul,
  ol {
    li {
      margin-bottom: 15px;
      color: #414141;
      list-style-position: outside;
      margin-left: 30px;
      font-size: 0.9em;

      div {
        float: right;
        margin-right: 2px;
      }
    }
  }
}
